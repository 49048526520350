<template>
  <!----Main Wrapper Start---->
  <div class="ms_main_wrapper">
    <app-side-menu />
    <app-header />
    <Suspense>
      <template #default>
        <!---Main Content Start--->
        <div class="ms_content_wrapper padder_top60">
          <app-home />
        </div>
      </template>
      <template #fallback>
        <app-loader />
      </template>
    </Suspense>
    <app-bottom-navbar/>
  </div>
</template>

<script>
import AppHeader from "../components/Include/AppHeader.vue";
import AppSideMenu from "../components/Include/AppSideMenu.vue";
import AppLoader from "../components/Spinners/AppLoader.vue";
import { defineAsyncComponent, Suspense } from "vue";
import AppBottomNavbar from '../components/Include/AppBottomNavbar.vue';
const AppHome = defineAsyncComponent(() =>
  import("../components/Pages/AppHome.vue")
);
export default {
  components: {
    AppSideMenu,
    AppHeader,
    AppLoader,
    AppHome,
    Suspense,
    AppBottomNavbar,
  },
  name: "Home",
};
</script>
